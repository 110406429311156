@layer components {
	.text-md {
		font-size: 0.9375rem;
		line-height: 1.5rem;
	}

	.h-18 {
		height: 4.5rem;
	}

	.w-inherit {
		width: inherit;
	}

	.h-inherit {
		height: inherit;
	}

	.w-76 {
		width: 20rem;
	}

	.w-112 {
		width: 450px;
	}

	.level-dropdown {
		top: 2.9025rem;
	}

	.search-container {
		width: calc(100% - 19rem);
	}

	.glass-overlay {
		background: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(20px);
	}
}

@layer utilities {
	.container {
		@apply max-w-screen-xl px-5 mx-auto sm:px-8 lg:px-20;
	}

	.tab-hover {
		@apply hover:border-b-4 hover:text-uc-blue-900 hover:border-uc-blue-900;
	}

	.tab-selected {
		@apply border-b-4 text-uc-blue-900 border-uc-blue-900;
	}

	.uni-mobile-nav-container {
		@apply flex gap-2 sm:gap-6 items-center w-full sm:text-gray-700 sm:w-auto sm:justify-start px-5 sm:px-0 py-5 sm:py-0 bg-white;
	}

	.tab-selected-uni-nav {
		@apply sm:border-b-4 sm:text-uc-blue-900 border-uc-blue-900 bg-uc-blue-900 text-white sm:bg-white #{!important};
	}

	//links
	.blue-link {
		@apply text-uc-blue-600 hover:text-uc-blue-800;
	}

	.blue-link-underline {
		@apply text-uc-blue-600 hover:text-uc-blue-800 underline;
	}

	//buttons
	.book-event-btn,
	.primary-green-btn,
	.get-prospectus-btn,
	.blue-button,
	.secondary-button,
	.call-uni-btn,
	.register-interest-btn,
	.white-header-btn,
	.exit-button {
		@apply px-5 py-3 text-sm font-bold text-center capitalize border-2 rounded-lg shadow-sm cursor-pointer;
	}

	.blue-button {
		@apply font-bold text-white border-uc-blue-900 hover:border-uc-blue-950 bg-uc-blue-900 hover:bg-uc-blue-950;
	}

	.uni-mobile-nav-button {
		@apply flex items-center whitespace-nowrap px-3 sm:px-0 py-2 text-sm font-bold sm:font-medium border rounded-full sm:rounded-none sm:border-l-0 sm:border-r-0 sm:border-t-0 sm:border-b-4 sm:border-transparent cursor-pointer sm:py-6 sm:tab-hover;
	}

	.red-button,
	.book-event-btn {
		@apply font-bold text-white bg-uc-red-900 border-uc-red-900 hover:border-uc-red-950 hover:bg-uc-red-950;
	}

	.primary-green-btn {
		@apply font-bold text-white bg-uc-green-900 border-uc-green-900 hover:border-uc-green-950 hover:bg-uc-green-950;
	}

	.white-button {
		@apply bg-white hover:bg-gray-200;
	}

	.white-header-btn {
		@apply font-bold text-uc-blue-900 bg-white border-white hover:border-gray-100 hover:bg-gray-100;
	}

	.secondary-button {
		@apply font-bold bg-white border-2 text-uc-blue-900 border-uc-blue-900 hover:bg-uc-blue-950 hover:border-uc-blue-950 hover:text-white;
	}

	.exit-button {
		@apply border-uc-red-900 text-white bg-uc-red-900 hover:border-uc-red-950 hover:bg-uc-red-950;
	}

	.get-prospectus-btn {
		@apply text-white bg-uc-purple-900 hover:bg-uc-purple-950 border-uc-purple-900 hover:border-uc-purple-950;
	}

	.register-interest-btn {
		@apply text-white bg-uc-clearing-200 hover:bg-uc-clearing-201 border-uc-clearing-200 hover:border-uc-clearing-201;
	}

	.call-uni-btn {
		@apply text-white bg-uc-clearing-100 hover:bg-uc-clearing-101 border-uc-clearing-100 hover:border-uc-clearing-101;
	}

	.btn-py-2 {
		@apply py-2 #{!important};
	}

	.secondary-header {
		@apply text-xl font-bold leading-6 text-gray-900 mb-8;
	}

	.carousel-navigation {
		@apply px-5 py-2 border rounded-md shadow-sm cursor-pointer sm:py-3 sm:px-7 focus:outline-none;
	}

	.carousel-pagination {
		@apply flex gap-1 select-none whitespace-nowrap;
	}

	.divider {
		@apply py-8 sm:py-16 border-b;
	}

	.divider-padding {
		@apply py-8 sm:py-16;
	}

	.divider-top {
		@apply pt-8 sm:pt-16;
	}

	.divider-top-border {
		@apply py-8 sm:py-16 border-t;
	}

	.unsaved-heart-custom-fill-opacity {
		@apply fill-current;
		fill-opacity: 0.2;
	}

	.input-focus-within {
		@apply focus-within:border-transparent focus-within:ring-2 focus-within:ring-gray-400 focus:outline-none;
	}
}
